import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { IEnvironment } from 'src/environments/environment.model';

import { MessageItemPost, MessageItemResponse, MessageThreadPost, MessageThreadResponse } from 'src/app/modules/core/models/message.model';
import { SettingsService } from 'src/app/modules/core/services/settings.service';

import { withSpinner } from 'src/app/modules/shared/extensions/withSpinner.extension';
import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';

@Injectable({
	providedIn: 'root'
})
export class MessageService extends UnsubscribeOnDestroy {	
	private settings!: IEnvironment;

	constructor(private _http: HttpClient,
		settingsService: SettingsService) {
		super();

		settingsService.getSettings().pipe(
			this.takeUntilDestroyed()
		).subscribe(settings => {
			this.settings = settings;
		});
	}
	
	getMessageThreads(salesAgreementId: number): Observable<MessageThreadResponse[]> {
		const url = `${this.settings?.apiUrl}message/salesAgreement/${salesAgreementId}/threads`;
		return this._http.get<MessageThreadResponse[]>(url);
	}

	getMessageItems(salesAgreementId: number, messageThreadId: number): Observable<MessageItemResponse[]> {
		const url = `${this.settings?.apiUrl}message/salesAgreement/${salesAgreementId}/threads/${messageThreadId}/items`;
		return this._http.get<MessageItemResponse[]>(url);
	}

	postMessageThread(salesAgreementId: number, data: MessageThreadPost): Observable<MessageThreadResponse> {
		const url = `${this.settings?.apiUrl}message/salesAgreement/${salesAgreementId}/threads`;
		return withSpinner(this._http).post<MessageThreadResponse>(url, data);
	}

	postMessageItem(salesAgreementId: number, messageThreadId: number, data: MessageItemPost): Observable<MessageItemResponse> {
		const url = `${this.settings?.apiUrl}message/salesAgreement/${salesAgreementId}/threads/${messageThreadId}/items`;
		return this._http.post<MessageItemResponse>(url, data);
	}

	markAsRead(salesAgreementId: number, messageThreadId: number): Observable<MessageThreadResponse> {
		const url = `${this.settings?.apiUrl}message/salesAgreement/${salesAgreementId}/threads/${messageThreadId}/read`;
		return this._http.put<MessageThreadResponse>(url, {});
	}
}