import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as MessageActions from 'src/app/modules/ngrx-store/message/message.actions';
import * as YourHomeActions from 'src/app/modules/ngrx-store/your-home/your-home.actions';
import { getSalesAgreementId } from 'src/app/modules/ngrx-store/your-home/your-home.selectors';

import { MessageService } from 'src/app/modules/core/services/message.service';

import { displayError } from 'src/app/modules/shared/utilities/notifications.utils';

@Injectable()
export class MessageEffects {
	constructor(
		private actions$: Actions,
		private messageService: MessageService,
		private store: Store
	) { }

	loadMessageThreads$ = createEffect(() => this.actions$.pipe(
		ofType(YourHomeActions.loadHomeDetailsSuccess, MessageActions.loadMessageThreads),
		withLatestFrom(this.store.select(getSalesAgreementId)),
		map(([, salesAgreementId]) => salesAgreementId),
		filter(Boolean), // Checks to make sure salesAgreementId is not undefined
		switchMap(salesAgreementId => 
			this.messageService.getMessageThreads(salesAgreementId).pipe(
				map((messageThreads) => MessageActions.loadMessageThreadsSuccess({ messageThreads })),
				catchError((err) => of(MessageActions.loadMessageThreadsFailure({ error: err })))
			))
	));

	loadMessageThreadsFailure$ = createEffect(() => this.actions$.pipe(
		ofType(MessageActions.loadMessageThreadsFailure),
		tap(err => {
			displayError(err.error);
		})
	),
	{ dispatch: false }
	);

	loadMessageItems$ = createEffect(() => this.actions$.pipe(
		ofType(MessageActions.loadMessageItems),
		withLatestFrom(this.store.select(getSalesAgreementId)),
		filter(([, salesAgreementId]) => Boolean(salesAgreementId)), // Checks to make sure salesAgreementId is not undefined
		switchMap(([action, salesAgreementId]) => 
			this.messageService.getMessageItems(salesAgreementId as number, action.messageThreadId).pipe(
				map((messageItems) => MessageActions.loadMessageItemsSuccess({ messageItems })),
				catchError((err) => of(MessageActions.loadMessageItemsFailure({ error: err })))
			))
	));

	loadMessageItemsFailure$ = createEffect(() => this.actions$.pipe(
		ofType(MessageActions.loadMessageItemsFailure),
		tap(err => {
			displayError(err.error);
		})
	),
	{ dispatch: false }
	);
	
	postMessageThread$ = createEffect(() => this.actions$.pipe(
		ofType(MessageActions.postMessageThread),
		withLatestFrom(this.store.select(getSalesAgreementId)),
		filter(([, salesAgreementId]) => Boolean(salesAgreementId)), // Checks to make sure salesAgreementId is not undefined
		switchMap(([action, salesAgreementId]) => 
			this.messageService.postMessageThread(salesAgreementId as number, action.messageThread).pipe(
				map((messageThread) => MessageActions.postMessageThreadSuccess({ messageThread })),
				catchError((err) => of(
					MessageActions.postMessageThreadFailure({
						error: err
					})
				))
			)
		)
	));

	postMessageThreadFailure$ = createEffect(() => this.actions$.pipe(
		ofType(MessageActions.postMessageThreadFailure),
		tap(err => {
			displayError(err.error);
		})
	),
	{ dispatch: false }
	);

	markAsRead$ = createEffect(() => this.actions$.pipe(
		ofType(MessageActions.markAsRead),
		withLatestFrom(this.store.select(getSalesAgreementId)),
		filter(([, salesAgreementId]) => Boolean(salesAgreementId)), // Checks to make sure salesAgreementId is not undefined
		switchMap(([action, salesAgreementId]) => 
			this.messageService.markAsRead(salesAgreementId as number, action.messageThreadId).pipe(
				map((messageThread) => MessageActions.markAsReadSuccess({ messageThread })),
				catchError((err) => of(
					MessageActions.markAsReadFailure({
						error: err
					})
				))
			)
		)
	));
}